export default {
  data() {
    return {};
  },
  methods: {
    setFilter: function(elmn) {
      this.cpage = 1;
      let _push;
      if (elmn === "reset") {
        _push = { path: this.path };
        this.resetSelect++;
      } else {
        let elm = elmn.srcElement;
        let query = this.$route.query;
        let nquery = { [elm.name]: elm.value };
        _push = { query: { ...query, ...nquery } };
      }
      this.$router.push(_push).catch(() => {});
    },
  },
};
