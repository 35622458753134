<template>
  <section>
    <div class="presentageline__content">
      <label class="p">{{ nameSring }}</label>
      <div class="presentageline__number" @click="go ? $router.push(go) : ''" :style="`--color:${color};`" v-if="number">
        {{ number }}
      </div>
    </div>
    <div class="presentageline">
      <div class="presentageline__container" @click="go ? $router.push(go) : ''" :style="`--witdh:${line}%; --color:${color};`"></div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    line: {
      type: String,
      default: 0,
    },
    number: {
      type: Number,
      default: 0,
    },
    nameSring: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    go: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.presentageline {
  margin-top: 5px;
  position: relative;
  display: flex;
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__number {
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    color: white;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    background-color: var(--color);
  }
  &__container {
    border-radius: 5px;
    background-color: var(--color);
    height: 10px;
    width: var(--witdh);
    @keyframes enter {
      0% {
        width: 0%;
      }
      100% {
        width: var(--witdh);
      }
    }
    animation: enter 1s ease-in-out;
  }
}
</style>
