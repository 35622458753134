import api from "@/api/api.js";
import setfilter from "@/mixins/setfilter";
export default {
  mixins: [setfilter],
  methods: {
    async getCases(query, pagination) {
      try {
        const { data } = await api.get("/helpdesk?" + query);
        this.hasMore = data.hasMore;
        if (!pagination) {
          this.cases = data.data;
        }
        return data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCasesByid(id) {
      const {
        data: { data },
      } = await api.get("/helpdesk/case?id=" + id);
      return data;
    },
    async createCase(params) {
      try {
        const createCase = await api.post("/helpdesk", params);
        return { ...createCase, error: false };
      } catch (e) {
        console.log(e);
        return { error: e.message };
      }
    },

    async updateCase(body, id) {
      try {
        const data = await api.put(`/helpdesk?_id=${id}`, body);
        return { ...data };
      } catch (error) {
        this.newError(error);
      }
    },
    ticketNumber() {
      const data = this.area;
      let letra;
      if (data)
        [letra] = data?.filter((area) => {
          if (area?._id === this.case?.area) {
            return area?.name;
          }
        });
      return letra?.name?.substring(0, 2) + (this.cases?.ticket < 10 ? "0" + this.cases?.ticket : this.cases?.ticket);
    },
    setQuery() {
      let query = this.$route.query;
      return Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join("&");
    },
    async sendMessage(message) {
      try {
        const { data } = await api.post("/helpdesk/messages", message);
        return { ...data.datas, error: false };
      } catch (e) {
        return { error: e.message };
      }
    },
    async getStatistics(date) {
      try {
        const { data } = await api.get("/helpdesk/statistics?date=" + date);
        return data.statistic;
      } catch (error) {
        this.newError(error);
      }
    },
    async getPersonAsing(search, area) {
      try {
        const { data } = await api.get(`/helpdesk/usersarea?search=${search}&area=${area}`);
        return data.data;
      } catch (error) {
        this.newError(error);
      }
    },
    validArea(id) {
      const data = this.areas.filter((area) => {
        if (area?._id === id) return area?.name;
      });
      return data[0]?.name;
    },
    typeRequirement() {
      try {
        const data = this.requirements;
        let letra;
        if (data)
          [letra] = data?.filter((requirement) => {
            if (+requirement?.value === +this.case?.requirement) {
              return requirement?.name;
            }
          });
        return letra?.name;
      } catch (error) {
        console.log("A ocurido un error en typeRequirement", error);
        return "";
      }
    },
    async getInfoCases(type, set) {
      let { data } = await api.get(`/helpdesk/info?type=${type}`);
      if (type === "requirement") {
        data = data?.data.sort((a, b) => +a?.index - +b?.index);
      } else data = data.data;
      if (set) this[set] = data;
      return data;
    },
    async updateRequirement(body, id) {
      try {
        const data = await api.put(`/helpdesk/info?_id=${id}`, body);
        return { ...data };
      } catch (error) {
        this.newError(error);
      }
    },
    async createRequirement(body) {
      try {
        const data = await api.post("/helpdesk/info", body);
        return { ...data };
      } catch (error) {
        console.log(error);
        this.newError(error);
      }
    },
    async deleteRequirement(id) {
      return await api.delete(`/helpdesk/info?_id=${id}`);
    },
    nweSuccess(message) {
      this.$alerts.dataSendSuccess(message);
    },
    newError(error) {
      clearTimeout(this.errorRepeat);
      this.errorRepeat = setTimeout(() => {
        this.$alerts.dataSendError({ message: error });
      }, 100);
    },
  },
  data() {
    return {
      errorRepeat: "",
      title: "Mesa de ayuda",
      status: [
        { name: "Estado", value: "all" },
        { name: "En espera", value: "await" },
        { name: "En proceso", value: "process" },
        { name: "Cerrado", value: "close" },
      ],
      priority: [
        { name: "Prioridad", value: "" },
        { name: "Baja", value: "low" },
        { name: "Media", value: "medium" },
        { name: "Urgente", value: "urgent" },
      ],
      statuses: [
        { cont: "await", title: "Tickets en Espera" },
        { cont: "process", title: "Tickets en proceso" },
        { cont: "close", title: "Tickets Cerrados" },
        { cont: "all", title: "Todos los Tickets" },
      ],
      subStatus: [
        { cont: "process", subStatus: "open", title: "Abiertos" },
        { cont: "process", subStatus: "notAsing", title: "Tickets No asignados" },
      ],
      filterDate: [
        {
          title: "Hoy",
          value: "today",
        },
        {
          title: "Ultima semana",
          value: "week",
        },
        {
          title: "Ultimo mes",
          value: "month",
        },
        {
          title: "Ultimo año",
          value: "year",
        },
      ],
    };
  },
};
