<template>
  <section class="helpdesk">
    <div class="helpdesk__container">
      <div class="helpdesk__container__content">
        <iconic class="helpdesk__select__iconic" name="calendar"></iconic>
        <select class="helpdesk__select" v-model="startDay" @change="getInfoStatistics">
          <option :selected="dato.title === 'Hoy'" :value="dato.value" v-for="(dato, idx) in filterDate" :key="idx">{{ dato.title }}</option>
        </select>
      </div>
      <div class="helpdesk__status">
        <div class="helpdesk__statu">
          <div
            class="helpdesk__status-item gcard"
            v-for="(status, idx) in statuses"
            :key="idx"
            @click="setFiltro({ status: status.cont, subStatus: status.subStatus ? status.subStatus : '' })"
          >
            <h5 class="helpdesk__status-title">{{ status.title }}</h5>
            <h5 class="helpdesk__status-cont">{{ status.total || 0 }}</h5>
          </div>
        </div>
      </div>
      <div class="helpdesk__statistics gcard">
        <div class="item1">
          <p class="p">Tickets por prioridad</p>
          <div class="helpdesk__statistics-item " v-for="(statistic, idx) in statistics" :key="idx">
            <lineStyle
              :number="statistics[idx].value || 0"
              :line="`${(statistics[idx].value * 100) / totalCases}`"
              :go="'/helpdesk/resume'"
              :nameSring="statistics[idx].label || '0'"
              :color="statistics[idx].color"
            ></lineStyle>
          </div>
        </div>
        <div class="item2">
          <p class="p">Cantidad tickets por dia</p>
          <line-chart :colors="['#b00', '#666']" height="180px" :data="dataStatistic"></line-chart>
        </div>
      </div>
      <div class="helpdesk__resumen gcard">
        <h5 class="tittle">Tipo de ticket recurrente</h5>
        <pie-chart class="upperCase" :library="library" :data="doubleStatistic"></pie-chart>
      </div>
    </div>
  </section>
</template>

<script>
import lineStyle from "../input/presentageline.vue";
import mixinCrud from "./mixinCrud";
export default {
  name: "helpdeskMain",
  mixins: [mixinCrud],
  components: {
    lineStyle,
  },
  data() {
    return {
      priority: [
        { value: "urgent", label: "Urgente", color: "#BD0909" },
        { value: "medium", label: "Media", color: "#FF5C00" },
        { value: "low", label: "Baja", color: "#F9D30D" },
      ],
      library: {
        is3D: true,
        pieSliceText: "value",
        legend: {
          position: "labeled",
          labeledValueText: "both",
          textStyle: {
            fontSize: 14,
          },
        },
        colors: ["#e62b26", "#50a2da", "#009e42", "#ec6667", "#f6c7b6", "#5e5e5e", "#9b9b9b"],
      },
      startDay: "month",
      statistic: [],
      statistics: [],
      totalCases: 0,
      typesrequirements: "",
      doubleStatistic: {},
      dataStatistic: {},
    };
  },
  methods: {
    setFiltro(filter) {
      this.$router.push({
        path: "/helpdesk/resume",
        query: {
          ...filter,
        },
      });
    },
    getPriority(priority) {
      return this.priority.find((item) => item.value === priority);
    },
    getTyperequirement(typerequirement) {
      const data = this.typesrequirements.find((item) => item._id === typerequirement);
      return data ? data.name : "";
    },
    recurrentTickets(ticket) {
      let data = ticket
        .map((item) => {
          return {
            [this.getTyperequirement(item._id)]: item.total,
          };
        })
        .reduce((a, b) => {
          for (let key in b) {
            if (b.hasOwnProperty(key)) {
              a[key] = (a[key] || 0) + b[key];
            }
          }
          return a;
        }, this.doubleStatistic);

      this.doubleStatistic = { ...data };
    },
    resetValues() {
      this.statistic = [];
      this.statistics = [];
      this.totalCases = 0;
      this.doubleStatistic = {};
      this.dataStatistic = {};
    },
    async getInfoStatistics() {
      this.resetValues();
      this.statistic = await this.getStatistics(this.startDay);

      this.statistic?.caseDays?.map((item) => {
        const date = new Date(item._id).toISOString().split("T")[0];
        this.dataStatistic[date] = (this.dataStatistic[date] || 0) + +item.total;
      });
      await this.getInfoCases("requirement", "typesrequirements");
      this.statistic?.priority.forEach((item) => {
        this.statistics.push({
          label: this.getPriority(item?._id)?.label || "Sin prioridad",
          color: this.getPriority(item?._id)?.color || "#F9D30D",
          value: item?.total || 0,
        });
      });
      this.totalCases = this.statistic?.total;
      this.statistic?.status.forEach((item) => {
        this.statuses?.forEach((status) => {
          if (status.cont === item?._id) status.total = item?.total;
          if (status.cont === "all") status.total = this.totalCases || 0;
        });
      });
      if (!this.statistic?.status.length)
        this.statuses.forEach((status) => {
          status.total = this.totalCases || 0;
        });
      this.recurrentTickets(this.statistic?.typesTickets);
      this.statistics.sort((a, b) => {
        if (a.label < b.label) return 1;
        if (a.label > b.label) return -1;
        return 0;
      });
    },
  },
  async beforeMount() {
    await this.getInfoStatistics();
  },
};
</script>

<style lang="scss">
.upperCase {
  text-transform: capitalize;
  font-weight: bold;
}
.p {
  font-weight: bold;
}
.helpdesk {
  &__container {
    &__content {
      display: flex;
      justify-content: space-around;
      height: 40px;
      width: 181px;
      padding: 5px 2px;
      border-radius: 5px;
    }
  }
  &__number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  &__statistics {
    p {
      text-align: center;
    }
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 15px;
    &-item {
      margin-top: 15px;
    }
  }
  position: relative;
  &__select {
    text-transform: capitalize;
    appearance: none;
    text-align: start;
    outline: none;
    width: 100%;
    height: 33px;
    color: $black;
    padding: 4px 8px 4px $mpadding * 2;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0.5px 2px 0px #00000069;
    &:hover {
      background-color: rgb(239, 239, 239);
    }
    &__iconic {
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }
  &__statu {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-items: auto;
    gap: 20px;
  }
  &__status {
    display: inherit;
    margin: 15px 0 15px 0;
    &-item {
      cursor: pointer;
      max-height: 90px;
      color: black;
      background-color: white;
      white-space: nowrap;
      &:hover {
        opacity: 0.7;
      }
    }
    &-title {
      color: black;
    }
    &-cont {
      color: $primary-color;
      font-weight: 600;
    }
  }
  &__resumen {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}
</style>
